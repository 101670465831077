<template>
  <div class="main">
    <h1>{{ $t("news.title") }}</h1>
    <blog></blog>
  </div>
</template>

<script>
import Blog from "./../components/Blog.vue";

export default {
  name: "News",
  components : { Blog }
}
</script>

<style scoped lang="scss">

.main {
  width:$blog;
  max-width: $max;
  margin:40px auto;
}

h1 {
    text-align: center;
    margin: 0 0 40px 0;
    color:$mainColor;
    text-transform: uppercase;
    font-size: 35px;
    font-weight: 400;
}

</style>