<template>
    <div class="blog-container">
        <no-posts v-if="!posts.length"></no-posts>
        <template v-else>
            <div class="post-item" v-for="(item, idx) in posts" :key="idx">
                <div class="col-img">
                    <img :src="`${$store.state.assetsUrl}/front-${item.imgFeatured}`" class="img-thumb" />
                    <div class="post-type">{{ getType(item.cat)}}</div>
                </div>
                <div class="col-infos">
                    <p class="post-date">{{ new Date(item.published).toLocaleDateString($i18n.locale.toUpperCase() + '-' + $i18n.locale, { year: 'numeric', month: 'long', day: 'numeric' })}}</p>
                    <h3>{{item.title}}</h3>
                    <div class="btn" @click="openPost(item)">{{$t("news.btn")}}</div>
                </div>
            </div>
            <pagination 
                v-if="pages"
                :pages="pages"
                :currentPage="currentPage"
                v-on:setPage="setPage"    
            ></pagination>
        </template>
    </div>
</template>

<script>
import axios from "axios";
import NoPosts from "./NoPosts.vue";
import Pagination from "./Pagination.vue";

export default {
    name : "Blog",
    components : { NoPosts, Pagination},
    data() {
        return {
            posts: [],
            currentPage : 1,
            pages : null

        }
    },
    mounted() {
        this.getData();
    },
    methods : {
        getType(cat) {
            let typeStr = "";
            switch(cat) {
                case 1 : 
                    typeStr = this.$t("news.news");
                    break;
                case 2 : 
                    typeStr = this.$t("news.events");
                    break;
                case 3 : 
                    typeStr = this.$t("news.pressRelease");
                    break;
                case 4 : 
                    typeStr = this.$t("news.pressReview");
                    break;
                case 5 : 
                    typeStr = this.$t("news.career");
                    break;
                case 6 : 
                    typeStr = this.$t("news.docs");
                    break;
                case 7 : 
                    typeStr = this.$t("news.shareholdersMeeting");
                    break;
            }
            return typeStr;
        },
        getData() {
            let config = {
                validateStatus: () => true,  
            };
            let vm = this;

            axios
                .get(`${this.$store.state.apiUrl}/public/posts/3/all/${this.$i18n.locale}/${this.currentPage}`,  config)
                .then(function (res) {                
                    if(res.status != 200) {
                        console.log('Error => ' + res.data.errors)                  
                    }else{
                        vm.posts = res.data.message;
                        vm.currentPage = res.data.pager.currentPage;
                        vm.pages = res.data.pager.totalPages;
                    
                    }
                });
        },
        openPost(post) {
            //si standar ou verture de la page puis populated
            if(post.type == 1) {
                this.$router.push({name : "BlogItem", params : { post : post.id}});
            }
            //si lien dof pdf on le DL
            else if(post.type == 2) {
                window.open(`${this.$store.state.apiUrl}/public/pdfs/${post.pdf}`, '_blank');
            }
            //sinon lien externe
            else {
                window.open(post.link, "_blank");
            }
        },
        setPage(page) {
            this.currentPage = page;
            this.getData();
        }
    },
    watch : {
        "$i18n.locale" : function() {
            this.getData();
        }
    }
}
</script>

<style lang="scss" scoped>
    .blog-container {
        width:100%;
        margin:80px auto;
    }

    .post-item {
        display: flex;
        width:100%;
        margin:20px 0;
    }

    .col-img {
        position: relative;
        width:40%;
    }

    .col-img img {
        max-width: 100%;
        width: 100%;
    }

    .post-type {
        position:absolute;
        top:0;
        left:0;
        padding:10px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        color:#fff;
        font-size:15px;
        font-weight: 300;
        background:rgba($mainColor, 0.8);
        text-align: center;
    }

    .post-date {
        color:$mainColor;
        font-size:18px;
        font-weight:400;
        margin:0;
    }

    h3 {
        color:$black;
        font-size:18px;
        font-weight:400;
        text-align: left;
    }

    .btn {
        text-transform: uppercase;
        font-size:15px;
        border:1px solid $mainColor;
    }



    .col-infos {
        width:calc(60% - 20px);
        margin-left:20px;
    }

    @media screen and (max-width:768px) {
        .post-item {
            flex-direction: column;
        }

        .col-img {
            width:100%;
        }

        .col-infos {
            margin:10px 0;
            width:100%;
        }

        .post-type {
            font-size:13px;
            width:100px;
            height:40px;
        }

        h3, .post-date {
            font-size:15px;
        }

        .btn {
            font-size:13px;
        }
    }
</style>